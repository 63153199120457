var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.inputFieldType === 'textField' ? _c('v-text-field', _vm._b({
    attrs: {
      "value": _vm.value,
      "data-cy": "".concat(_vm.dataCy, "Input"),
      "inset": ""
    },
    on: {
      "input": _vm.changeInput,
      "change": _vm.changeEvent
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs), false)) : _vm._e(), _vm.inputFieldType === 'textArea' ? _c('v-textarea', _vm._b({
    attrs: {
      "value": _vm.value,
      "data-cy": "".concat(_vm.dataCy, "TextArea"),
      "inset": ""
    },
    on: {
      "input": _vm.changeInput,
      "change": _vm.changeEvent
    }
  }, 'v-textarea', Object.assign({}, _vm.$attrs), false)) : _vm._e(), _vm.inputFieldType === 'select' ? _c('v-select', _vm._b({
    attrs: {
      "value": _vm.value,
      "items": _vm.inputItems,
      "data-cy": "".concat(_vm.dataCy, "Select"),
      "inset": ""
    },
    on: {
      "input": _vm.changeInput,
      "change": _vm.changeEvent
    }
  }, 'v-select', Object.assign({}, _vm.$attrs), false)) : _vm._e(), _vm.inputFieldType === 'autocomplete' ? _c('v-autocomplete', _vm._b({
    attrs: {
      "value": _vm.value,
      "items": _vm.inputItems,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      "data-cy": "".concat(_vm.dataCy, "Autocomplete"),
      "inset": ""
    },
    on: {
      "input": _vm.changeInput,
      "change": _vm.changeEvent
    }
  }, 'v-autocomplete', Object.assign({}, _vm.$attrs), false)) : _vm._e(), _vm.inputFieldType === 'switch' ? _c('v-switch', _vm._b({
    attrs: {
      "input-value": _vm.value,
      "data-cy": "".concat(_vm.dataCy, "Switch"),
      "inset": ""
    },
    on: {
      "change": _vm.changeEvent,
      "input": _vm.changeInput
    }
  }, 'v-switch', Object.assign({}, _vm.$attrs), false)) : _vm._e(), _vm.inputFieldType === 'date' ? _c('BaseDatePicker', _vm._b({
    attrs: {
      "data-cy": "datePicker",
      "value": _vm.value,
      "inset": ""
    },
    on: {
      "input": _vm.changeInput,
      "change": _vm.changeEvent
    }
  }, 'BaseDatePicker', Object.assign({}, _vm.$attrs), false)) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }