
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "FormElement",
  components: { BaseDatePicker },
  props: {
    inputFieldType: {
      type: String,
      validator: (val: string) => ["textField", "textArea", "select", "switch", "date", "autocomplete"].includes(val),
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    value: {
      // type: [String, Number, Object],
      required: true,
    },
    dataCy: { type: String, required: false },
    itemText: {
      type: [String, Function],
      required: false,
    },
    itemValue: {
      type: String,
      required: false,
    },
    inputItems: {
      type: Array,
      required: false,
    },
  },
  setup(props, { emit }) {
    const changeInput = (newValue: any) => {
      emit("input", newValue);
    };

    const changeEvent = (newValue: any) => {
      emit("changeEvent", newValue);
    };

    return {
      changeInput,
      changeEvent,
    };
  },
});
